import { FC } from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Link className="logo" to={"/"}>
      <img
        src="https://ajax-group.ru/wp-content/uploads/2022/07/logo-90-2.png"
        alt="logo"
      />
    </Link>
  );
};
