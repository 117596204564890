import React, { useEffect, useState } from "react";
import Home from "./components/Home/Home";
import "./App.css";
import Header from "./components/Header/Header";
import Burger from "./components/BurgerMenu/Burger";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { RootState } from "./store/store";
import { Footer } from "./components/Footer/Footer";
import { MerchandiseItemPage } from "./components/Pages/ItemPage/MerchandiseItemPage";
import { MerchandiseItemPageAdmin } from "./components/Pages/ItemPage/Admin/MerchandiseitemPageAdmin";
import { ShopPage } from "./components/Pages/ShopPage/ShopPage";
import useFetch from "./hooks/useFetch";
import { serverUrl } from "./config";
import { useActions } from "./hooks/useActions";
import { OrderPage } from "./components/Pages/OrderPage/OrderPage";
import { Pageplaceholder } from "./components/Pages/PagePlaceholder";

function App() {
  const token = JSON.parse(localStorage.getItem("token") || "null");

  const { setLoginState } = useActions();

  const isLoginSuccesful = useSelector(
    (state: RootState) => state.loginReducer.isLoginSuccessful
  );

  const { data, error } = useFetch<{ error?: string; success?: string }>(
    serverUrl + "admin-login/" + token
  );

  useEffect(() => {
    if (data && data.error) {
      setLoginState(false);
    }
    if (data && data.success) {
      setLoginState(true);
    }
    if (error) {
      setLoginState(false);
    }
  }, [data]);

  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState<
    "active" | "idle" | "unactive"
  >("idle");
  return (
    <Router>
      <div className="App">
        <a href="/mufta-sprinclernaia-privarnaia/"></a>
        <Header setIsBurgerMenuActive={setIsBurgerMenuActive} />
        <Burger
          setIsBurgerMenuActive={setIsBurgerMenuActive}
          burgerMenuState={isBurgerMenuActive}
        />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="shop/:id/admin"
            element={<MerchandiseItemPageAdmin />}
          ></Route>
          <Route path="shop/:id" element={<MerchandiseItemPage />}></Route>
          <Route path="shop" element={<ShopPage />}></Route>
          <Route path="checkout" element={<OrderPage />}></Route>
          <Route
            path="mufta-sprinclernaia-privarnaia/"
            element={<Navigate to="/" replace={true} />}
          ></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
