import { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

interface Props {
  titleItemsArray: {
    title: string;
    url: string;
    isNested?: boolean;
    isNestedAsList?: boolean;
    itemsNested?: { title: string; url: string }[];
  }[];
  linksArray: string[];
  isActive: boolean;
}

export const ContextMenu: FC<Props> = ({
  titleItemsArray,
  linksArray,
  isActive,
}) => {
  return (
    <div
      className="link_context_menu"
      style={{
        height: `${
          titleItemsArray.reduce((acc, curr) => {
            return curr.isNested && curr.isNestedAsList
              ? acc + curr.itemsNested!.length
              : acc + 1;
          }, 0) * 40
        }px`,
      }}
    >
      <div className="links_wrapper">
        {titleItemsArray.map((item, i) => (
          <>
            {item.isNested && item.isNestedAsList ? (
              <>
                <ul className="link_context_menu_nested_list" key={i}>
                  {item.title}:
                  {item!.itemsNested?.map((e, i) => (
                    <li key={i}>
                      <Link to={e.url} className="link_context_menu_link">
                        — {e.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : item.isNested ? (
              <div
                className="link_context_menu_additional_menu_wrapper"
                key={i}
              >
                <Link to={item.url} className="link_context_menu_link">
                  {item.title} <span>›</span>
                </Link>
                <ul className="link_context_menu_adittional_menu">
                  {item!.itemsNested?.map((e, i) => (
                    <li key={i}>
                      <Link to={e.url} className="link_context_menu_link">
                        {e.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <Link to={item.url} className="link_context_menu_link">
                {item.title}
              </Link>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
