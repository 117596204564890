import { Carousel } from "../Carousel/Carousel";
import { MerhandiseCards } from "./MerchandiseCards/MerchandiseCards";
import "./Home.scss";
import { Services } from "./Services/Services";
import { Advantages } from "./Advantages/Advantages";
import { CompletedContracts } from "./CompletedContracts/Carousel/CompletedContracts";
import { Partners } from "./Partners/Partners";

const Home = () => {
  return (
    <div className="home_wrapper">
      <Carousel></Carousel>
      <Services></Services>
      <MerhandiseCards />
      <Advantages></Advantages>
      <CompletedContracts></CompletedContracts>
      <Partners></Partners>
    </div>
  );
};
export default Home;
