import { FC, useState, useRef, useEffect } from "react";
import { FullMerchandiseItemInfo } from "../../../../../types";
import axios from "axios";
import { serverUrl } from "../../../../../config";

interface Props {
  isHeader: boolean;
  value: any;
  propertyBeingEditedName: string;
  propertiesTable: FullMerchandiseItemInfo["item_properties_table"];
  setPropertiesTable: React.Dispatch<
    React.SetStateAction<FullMerchandiseItemInfo["item_properties_table"]>
  >;
  rowIndex?: number;
  postfix?: string;
  dbKey: number;
}

export const PropertiesTableCell: FC<Props> = ({
  propertiesTable,
  setPropertiesTable,
  isHeader,
  value,
  propertyBeingEditedName,
  rowIndex,
  postfix,
  dbKey,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const tableCellWrapper = useRef<any>(null);

  async function editPropertiesTable() {
    if (isHeader && !rowIndex) {
      setPropertiesTable({
        ...propertiesTable,
        deviation: {
          ...propertiesTable.deviation,
          [propertyBeingEditedName]: inputRef.current?.value,
        },
      });
    } else if (rowIndex || rowIndex == 0) {
      setPropertiesTable({
        ...propertiesTable,
        rows: propertiesTable.rows.map((e, i) => {
          if (i == rowIndex) {
            return { ...e, [propertyBeingEditedName]: inputRef.current?.value };
          }
          return e;
        }),
      });
    }
  }

  useEffect(() => {
    const clickListenerHandler = async (e: MouseEvent) => {
      if (
        inputRef.current &&
        tableCellWrapper.current &&
        !tableCellWrapper.current.contains(e.target as Node)
      ) {
        editPropertiesTable();
        if (isHeader) {
          await axios.post(`${serverUrl}/product-table/edit-row/${dbKey}`, {
            ...propertiesTable,
            deviation: {
              ...propertiesTable.deviation,
              [propertyBeingEditedName]: inputRef.current.value,
            },
          });
        } else {
          await axios.post(`${serverUrl}/product-table/edit-row/${dbKey}`, {
            ...propertiesTable,
            rows: propertiesTable.rows.map((e, i) => {
              if (i == rowIndex) {
                return {
                  ...e,
                  [propertyBeingEditedName]: inputRef.current?.value,
                };
              }
              return e;
            }),
          });
        }
        setShowInput(false);
      }
    };
    const keyDownListenerHandler = async (e: KeyboardEvent) => {
      if (e.key == "Enter" && inputRef.current) {
        editPropertiesTable();
        if (isHeader) {
          await axios.post(`${serverUrl}/product-table/edit-row/${dbKey}`, {
            ...propertiesTable,
            deviation: {
              ...propertiesTable.deviation,
              [propertyBeingEditedName]: inputRef.current.value,
            },
          });
        } else {
          await axios.post(`${serverUrl}/product-table/edit-row/${dbKey}`, {
            ...propertiesTable,
            rows: propertiesTable.rows.map((e, i) => {
              if (i == rowIndex) {
                return {
                  ...e,
                  [propertyBeingEditedName]: inputRef.current?.value,
                };
              }
              return e;
            }),
          });
        }
        setShowInput(false);
      }
    };
    if (showInput) {
      document.addEventListener("click", clickListenerHandler);
      document.addEventListener("keydown", keyDownListenerHandler);
    } else {
      document.removeEventListener("click", clickListenerHandler);
      document.removeEventListener("keydown", keyDownListenerHandler);
    }
  }, [showInput, inputValue]);

  return (
    <>
      {isHeader ? (
        <th ref={tableCellWrapper} onDoubleClick={() => setShowInput(true)}>
          {showInput ? (
            <input
              defaultValue={inputValue}
              ref={inputRef}
              onChange={(e) => setInputValue(e.target.value)}
            ></input>
          ) : (
            <span>
              {inputValue}
              {postfix}
            </span>
          )}
        </th>
      ) : (
        <td ref={tableCellWrapper} onDoubleClick={() => setShowInput(true)}>
          {showInput ? (
            <input
              defaultValue={inputValue}
              ref={inputRef}
              onChange={(e) => setInputValue(e.target.value)}
            ></input>
          ) : (
            <span>
              {inputValue}
              {postfix}
            </span>
          )}
        </td>
      )}
    </>
  );
};
