import { FC, useState } from "react";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { MerchandiseItemInfo } from "../../../types";
import { RootState } from "../../../store/store";
import { Link } from "react-router-dom";

interface CartItemInfo extends MerchandiseItemInfo {
  cartItemsCount: number;
}

interface Props {
  info?: CartItemInfo;
}

export const MerсhandiseCard: FC<Props> = ({ info }) => {
  const { setCartState } = useActions();

  const itemsArray = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );

  const addItemToCart = () => {
    /* setCartItems(info as MerchandiseItemInfo); */
    console.log(itemsArray);
    if (itemsArray.find((e) => e.item_key === info?.item_key)) {
      const foundItemIndex = itemsArray.findIndex(
        (e) => e.item_key === info?.item_key
      );
      setCartState(
        itemsArray.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount + 1 };
          }
          return e;
        })
      );
    } else {
      setCartState([
        ...itemsArray,
        { ...(info as CartItemInfo), cartItemsCount: 1 },
      ]);
    }
  };

  const removeItemFromCart = () => {
    if (
      itemsArray.find((e) => e.item_key === info?.item_key) &&
      itemsArray.find((e) => e.item_key === info?.item_key)!.cartItemsCount > 1
    ) {
      const foundItemIndex = itemsArray.findIndex(
        (e) => e.item_key === info?.item_key
      );
      setCartState(
        itemsArray.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount - 1 };
          }
          return e;
        })
      );
    } else {
      setCartState(itemsArray.filter((e) => e.item_key != info?.item_key));
    }
  };

  return (
    <div className="merchandise_card">
      {info ? (
        <>
          <img src={info.item_img} alt="Изображение товара" />
          <Link to={`/shop/${info.item_key}`}>
            <p className="card_title">{info.item_title}</p>
          </Link>
          <a href={`/shop/${info.item_key}/admin`}></a>
          <span className="card_cost">₽{info.item_cost}</span>{" "}
          {itemsArray.some((e) => e.item_key === info?.item_key) ? (
            <div className="card_controls_wrapper">
              <div className="card_controls">
                <div className="card_control_button" onClick={addItemToCart}>
                  <span>+</span>
                </div>
                <input
                  className="card_control_count"
                  value={
                    itemsArray.find((e) => e.item_key === info?.item_key)
                      ?.cartItemsCount
                  }
                  onChange={(e) => {
                    const foundItemIndex = itemsArray.findIndex(
                      (item) => item.item_key === info?.item_key
                    );
                    console.log(e.target.value);
                    setCartState(
                      itemsArray.map((item, i) => {
                        if (i == foundItemIndex) {
                          return {
                            ...item,
                            cartItemsCount: parseInt(e.target.value) || 0,
                          };
                        }
                        return item;
                      })
                    );
                  }}
                ></input>
                <div
                  className="card_control_button"
                  onClick={removeItemFromCart}
                >
                  <span>-</span>
                </div>
              </div>
            </div>
          ) : (
            <button className="btn-grad" onClick={addItemToCart}>
              Купить
            </button>
          )}
        </>
      ) : (
        <>
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
};
