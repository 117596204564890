import { FC } from "react";

interface Props {
  imageUrl: string;
  description: string;
  slideLink: string;
}

export const CompletedContractsSlide: FC<Props> = ({
  imageUrl,
  description,
  slideLink,
}) => {
  return (
    <article className="completed_contracts_slide">
      <section>
        <div>
          <span>{description}</span>
        </div>
      </section>
      <a href={slideLink}>
        <img src={imageUrl} alt="" />
      </a>
      {/*  <button className="slide_more_button">Подробнее</button> */}
    </article>
  );
};
