import { serverUrl } from "../../../config";
import useFetch from "../../../hooks/useFetch";
import { TitleUnderline } from "../TitileUndeline";
import "./Partners.scss";
import { PartnersItem } from "./PartnersItem";

export const Partners = () => {
  const { data, error } = useFetch<
    { url: string; title: string; img: string }[]
  >(serverUrl + "partners");

  return (
    <article className="partners_wrapper">
      <h1>ПАРТНЕРЫ</h1>
      <TitleUnderline></TitleUnderline>
      <section className="partners_container">
        {data ? (
          data.map((e, i) => (
            <PartnersItem itemImg={e.img} itemTitle={e.title} itemUrl={e.url} />
          ))
        ) : (
          <></>
        )}
      </section>
    </article>
  );
};
