import { TitleUnderline } from "../TitileUndeline";
import "./Services.scss";

export const Services = () => {
  return (
    <article className="services_wrapper">
      <h1>УСЛУГИ</h1>
      <TitleUnderline />
      <section className="services_content">
        <div className="services_item">
          <img
            src="https://ajax-group.ru/wp-content/uploads/2022/06/proektirovanie.jpg"
            alt="Проектирование"
          />
          <h2>Проектирование</h2>
        </div>
        <div className="services_item">
          <img
            src="https://ajax-group.ru/wp-content/uploads/2022/06/montazhnye-raboty-aupt-vpv.jpg"
            alt="Монтажные работы АУПТ, ВПВ"
          />
          <h2>Монтажные работы АУПТ, ВПВ</h2>
        </div>
        <div className="services_item">
          <img
            src="https://ajax-group.ru/wp-content/uploads/2023/01/elektromontazhnye-raboty-1.jpg"
            alt="Электромонтажные работы ЭОМ, ЭС, СС"
          />
          <h2>Электромонтажные работы ЭОМ, ЭС, СС</h2>
        </div>
        <div className="services_item">
          <img
            src="https://ajax-group.ru/wp-content/uploads/2022/08/livnevaia-kanalizatciia2.jpg"
            alt="Ливневая канализация"
          />
          <h2>Ливневая канализация</h2>
        </div>
        <div className="services_item">
          <img
            src="https://ajax-group.ru/wp-content/uploads/2022/06/ekspluatatciia.jpg"
            alt="Эксплуатация"
          />
          <h2>Эксплуатация</h2>
        </div>
      </section>
    </article>
  );
};
