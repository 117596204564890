import { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { FullMerchandiseItemInfo } from "../../../../types";
import axios from "axios";

interface Props {
  setDisplayEditMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditMenu: FC<Props> = ({ setDisplayEditMenu }) => {
  const formRef = useRef<HTMLFormElement>(null);

  const requestUrl = useSelector(
    (state: RootState) => state.editMenuReducer.url
  );
  const editMenuState = useSelector(
    (state: RootState) => state.editMenuReducer.propertiesArray
  );
  useEffect(() => {
    const keyDownEventListener = async (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        await postChanges();
      }
    };
    window.addEventListener("keydown", keyDownEventListener);
    return () => {
      window.removeEventListener("keydown", keyDownEventListener);
    };
  }, []);
  console.log();
  const postChanges = async () => {
    console.log(requestUrl);
    const inputValues = Array.from(formRef.current!.children).map((e, i) =>
      (e.children[1] as HTMLInputElement)?.type == "checkbox"
        ? +(e.children[1] as HTMLInputElement).checked
        : (e.children[1] as HTMLInputElement).value.trim()
    );
    console.log(inputValues);
    const reqBody = editMenuState.reduce((acc, curr, i) => {
      return {
        ...acc,
        [curr.propertyName]: curr.notEditable
          ? curr.initialValue
          : curr.dataType == "number"
          ? parseInt(inputValues[i] as string)
          : inputValues[i],
      };
    }, {});

    const response = await axios.post(requestUrl, reqBody);
    console.log(response);

    window.location.reload();
  };
  return (
    <div className="merchandise_context_menu">
      <div
        className="merchandise_close_menu_button"
        onClick={() => setDisplayEditMenu(false)}
      >
        <svg
          fill="#000000"
          height="200px"
          width="200px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 490 490"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
          </g>
        </svg>
      </div>
      <form className="edit_menu_form" ref={formRef} action="">
        {editMenuState.map((e, i) => {
          if (!e.notEditable) {
            return e.type == "checkbox" ? (
              <div key={i} className="form_field_input_container">
                <span>{e.title}</span>
                <input
                  type="checkbox"
                  defaultChecked={e.initialValue ? true : false}
                />
              </div>
            ) : e.type == "text" ? (
              <div key={i} className="form_field_input_container">
                <span>{e.title}</span>
                <input type="text" defaultValue={e.initialValue} />
              </div>
            ) : (
              <div key={i} className="form_field_input_container">
                <span>{e.title}</span>
                <textarea defaultValue={e.initialValue}></textarea>
              </div>
            );
          } else return <></>;
        })}
      </form>
      <button onClick={() => postChanges()} className="edit_menu_submit_button">
        Подтвердить изменения
      </button>
    </div>
  );
};
