import { FC, useState } from "react";
import { ArrowButton } from "./ArrowButton";
import { ContextMenu } from "./ContextMenu";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  url: string;
  additionalLinksTitles?: {
    title: string;
    url: string;
    isNested?: boolean;
    isNestedAsList?: boolean;
    itemsNested?: { title: string; url: string }[];
  }[];
  additionalLinks?: string[];
}

export const NavigationLink: FC<Props> = ({
  title,
  additionalLinks,
  additionalLinksTitles,
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  return (
    <>
      <div
        onMouseOver={() => setShowContextMenu(true)}
        onMouseLeave={() => setShowContextMenu(false)}
      >
        <div className="link_wrapper">
          <li className="navbar-full_navigation_link">
            <Link to="/">
              {title} {additionalLinksTitles ? <ArrowButton /> : <></>}
            </Link>
          </li>
          {additionalLinksTitles ? (
            <ContextMenu
              titleItemsArray={additionalLinksTitles}
              linksArray={[]}
              isActive={showContextMenu}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
