import "./Carousel.scss";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { CarouselSlide } from "./CarouselSlide";
import { CarouselIndicator } from "./CarouselIndicator";
import useFetch from "../../hooks/useFetch";
import { serverUrl } from "../../config";
import { Banners } from "../../types";
export const Carousel = () => {
  const [animateSlider, setAnimateSlider] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const slidesRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<any>(null);
  const [sliderStyles, setSliderStyles] = useState<React.CSSProperties>({});

  const { data, error } = useFetch<Banners>(serverUrl + "banners");

  const localBanners = JSON.parse(
    localStorage.getItem("banners_local") || "[]"
  );

  const startSliderInterval = () => {
    console.log(slideIndex);
    setSlideIndex((prev) => {
      if (prev + 1 < (slidesRef.current as HTMLDivElement).children.length) {
        return prev + 1;
      } else return prev;
    });
  };

  useEffect(() => {
    intervalRef.current = setInterval(startSliderInterval, 5000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (data) {
      localStorage.setItem("banners_local", JSON.stringify(data));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setSliderStyles({
        transform: `translateX(${(100 / data.length) * -slideIndex}%)`,
        transition: `transform ${0.5}s`,
      });
      console.log(slideIndex);
    }
  }, [slideIndex]);

  console.log(data);

  return (
    <div className="carousel_wrapper">
      <div className="carousel_content_wrapper">
        <button
          className="carousel_button left"
          onClick={() => {
            if (slideIndex - 1 > -1) {
              setSlideIndex(slideIndex - 1);
              clearInterval(intervalRef.current);
              console.log(slideIndex);
            }
          }}
        >
          ‹
        </button>
        <div className="carousel_slides_wrapper">
          <div ref={slidesRef} style={sliderStyles} className="carousel_slides">
            {data ? (
              data.map((e, i) => (
                <CarouselSlide
                  img={e.img}
                  title={e.title}
                  key={i}
                  url={e.url}
                />
              ))
            ) : (
              <article className="carousel_slide">
                {/*  <button className="slide_more_button">Подробнее</button> */}
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <section>
                  <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </section>
              </article>
            )}
          </div>
        </div>
        <button
          className="carousel_button right"
          onClick={() => {
            if (
              slideIndex + 1 <
              (slidesRef.current as HTMLDivElement).children.length
            ) {
              setSlideIndex(slideIndex + 1);
              clearInterval(intervalRef.current);
              console.log(slideIndex);
            }
          }}
        >
          ›
        </button>
        <CarouselIndicator
          slideIndex={slideIndex}
          slides={data || localBanners || []}
          setSlideIndex={setSlideIndex}
        />
      </div>
    </div>
  );
};
