import { useLocation } from "react-router";
import "./ItemPage.scss";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { FullMerchandiseItemInfo } from "../../../types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useActions } from "../../../hooks/useActions";
import { MerchandiseItemInfo } from "./MerchandiseItemInfo";
import { serverUrl } from "../../../config";

export const MerchandiseItemPage = () => {
  const url = useLocation();
  const { data, error, state } = useFetch<FullMerchandiseItemInfo[]>(
    `${serverUrl}shop/${url.pathname.split("/")[2]}`
  );
  console.log(data);

  const itemsArray = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );
  const [inputValue, setInputValue] = useState(
    itemsArray.find((e) => e.item_key === parseInt(url.pathname.split("/")[2]))
      ?.cartItemsCount || 0
  );

  const { setCartState } = useActions();

  const addItemToCart = () => {
    /* setCartItems(info as MerchandiseItemInfo); */
    console.log(itemsArray);
    if (data && itemsArray.find((e) => e.item_key === data[0]?.item_key)) {
      const foundItemIndex = itemsArray.findIndex(
        (e) => e.item_key === data[0]?.item_key
      );
      setCartState(
        itemsArray.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount + inputValue };
          }
          return e;
        })
      );
    } else {
      if (data) {
        setCartState([
          ...itemsArray,
          { ...data[0], cartItemsCount: inputValue },
        ]);
      }
    }
  };

  useEffect(() => {
    if (data) {
      console.log(data[0]);
    }
  }, [data]);

  return (
    <div className="merchandise_page_wrapper">
      <section className="merchandise_page_content">
        <span className="merchandise_page_navigation">
          <Link to="/">Главная</Link> / <Link to="/shop">Продукция</Link> /
          <Link to="/">
            {data ? data[0].item_category : "Категория товара"}
          </Link>
          /<a>{data ? data[0].item_title : "Муфта"}</a>
        </span>
        {data ? (
          <div className="merchandise_page">
            <div className="merchandise_menu">
              <img className="merchandise_img" src={data[0].item_img}></img>
              <div className="merchandise_block">
                <h1 className="block_main_heading">{data[0].item_title}</h1>
                <div className="block_underline"></div>
                <div className="star_rating_wrapper">
                  <div className="star_rating">
                    <div
                      className="star_rating_active"
                      style={{
                        width: `${
                          29 *
                            (data[0]?.item_reviews?.reviews?.reduce(
                              (prev, curr) => {
                                return prev + curr.rating;
                              },
                              0
                            ) /
                              data[0]?.item_reviews?.reviews?.length) || 0
                        }px`,
                      }}
                    >
                      {Array(5)
                        .fill(null)
                        .map((e, i) => (
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 47.94 47.94"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>{" "}
                            </g>
                          </svg>
                        ))}
                    </div>
                    {Array(5)
                      .fill(null)
                      .map((e, i) => (
                        <svg
                          fill="#000000"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 36.09 36.09"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <path d="M36.042,13.909c-0.123-0.377-0.456-0.646-0.85-0.688l-11.549-1.172L18.96,1.43c-0.16-0.36-0.519-0.596-0.915-0.596 s-0.755,0.234-0.915,0.598L12.446,12.05L0.899,13.221c-0.394,0.04-0.728,0.312-0.85,0.688c-0.123,0.377-0.011,0.791,0.285,1.055 l8.652,7.738L6.533,34.045c-0.083,0.387,0.069,0.787,0.39,1.02c0.175,0.127,0.381,0.191,0.588,0.191 c0.173,0,0.347-0.045,0.503-0.137l10.032-5.84l10.03,5.84c0.342,0.197,0.77,0.178,1.091-0.059c0.32-0.229,0.474-0.633,0.391-1.02 l-2.453-11.344l8.653-7.737C36.052,14.699,36.165,14.285,36.042,13.909z M25.336,21.598c-0.268,0.24-0.387,0.605-0.311,0.957 l2.097,9.695l-8.574-4.99c-0.311-0.182-0.695-0.182-1.006,0l-8.576,4.99l2.097-9.695c0.076-0.352-0.043-0.717-0.311-0.957 l-7.396-6.613l9.87-1.002c0.358-0.035,0.668-0.264,0.814-0.592l4.004-9.077l4.003,9.077c0.146,0.328,0.456,0.557,0.814,0.592 l9.87,1.002L25.336,21.598z"></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      ))}
                  </div>
                  <p>
                    ({data[0]?.item_reviews?.reviews?.length || 0} отзывов
                    клиентов)
                  </p>
                </div>
                <div className="block_item_cost">
                  <h2>₽{data[0].item_cost}</h2>
                  <p>Дилерам представляются особые условия</p>
                </div>
                <div className="block_underline"></div>
                <div className="block_item_buttons">
                  <h2>Шт:</h2>
                  <button
                    className="count_change_button"
                    onClick={() => {
                      setInputValue((e) => e + 1);
                    }}
                  >
                    +
                  </button>
                  <input
                    type="text"
                    value={inputValue || 0}
                    onChange={(e) =>
                      setInputValue(parseInt(e.target.value) || 0)
                    }
                  />
                  <button
                    className="count_change_button"
                    onClick={() => {
                      if (inputValue - 1 >= 0) {
                        setInputValue((e) => e - 1);
                      }
                    }}
                  >
                    -
                  </button>
                  <button
                    className="add_to_cart_button"
                    onClick={addItemToCart}
                  >
                    В КОРЗИНУ
                  </button>
                </div>
                <div className="block_underline"></div>
                <div className="block_item_property">
                  <h2>Артикул:</h2>
                  {data[0].item_vendor_code}
                </div>
                <div className="block_item_property">
                  <h2>Категория:</h2>
                  {data[0].item_category}
                </div>
              </div>
            </div>
            <MerchandiseItemInfo
              info={data[0]}
              titlesArray={[
                "характеристики",
                "описание",
                "видео",
                "документы",
                `отзывы (${data[0]?.item_reviews?.reviews?.length || 0})`,
              ]}
            />
          </div>
        ) : (
          <div className="loading_background">
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};
