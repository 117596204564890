import { ArrowButton } from "./ArrowButton";
import { NavigationLink } from "./NavigationLink";

export const NavigationFull = () => {
  return (
    <>
      <ul className="navbar-full_navigation">
        <NavigationLink title="ГЛАВНАЯ" url="/" />
        <NavigationLink
          title="О КОМПАНИИ"
          url="about"
          additionalLinks={[]}
          additionalLinksTitles={[
            {
              title: "Сертификаты и лицензии",
              url: "/about/sertifikaty/",
              isNested: false,
            },
            {
              title: "Выполненные объекты",
              url: "/about/vypolnennye-obekty",
              isNested: false,
            },
            {
              title: "Наши партнеры",
              url: "/about/nashi-partnery/",
            },
            {
              title: "Новости",
              url: "/about/news/",
            },
          ]}
        />
        <NavigationLink
          title="УСЛУГИ"
          url="uslugi"
          additionalLinks={[]}
          additionalLinksTitles={[
            {
              title: "Проектирование",
              url: "/uslugi/proektirovanie/",
              isNested: true,
              itemsNested: [
                {
                  title: "АУПТ",
                  url: "/uslugi/proektirovanie/aupt/",
                },
                {
                  title: "ВПВ",
                  url: "/uslugi/proektirovanie/vpv/",
                },
                {
                  title: "АПС",
                  url: "/uslugi/proektirovanie/vpv/",
                },
                {
                  title: "СОУЭ",
                  url: "/uslugi/proektirovanie/soue/",
                },
                {
                  title: "АППЗ",
                  url: "/uslugi/proektirovanie/appz/",
                },
              ],
            },
            {
              title: "Монтажные работы АУПТ, ВПВ",
              url: "/uslugi/montazhnye-raboty-aupt-vpv/",
              isNested: false,
            },
            {
              title: "Электромонтажные работы ЭОМ, ЭС, СС",
              url: "/uslugi/elektromontazhnye-raboty-eom-es-ss/",
            },
            {
              title: "Ливневая канализация",
              url: "/uslugi/livnevaia-kanalizatciia/",
            },
            {
              title: "Эксплуатация",
              url: "/uslugi/ekspluatatciia/",
            },
          ]}
        />
        <NavigationLink
          title="ПРОДУКЦИЯ"
          url="/"
          additionalLinks={[]}
          additionalLinksTitles={[
            {
              title: "Муфта спринклерная",
              url: "/",
              isNested: false,
            },
            {
              title: "Защита оросителя",
              url: "/shop/zashchita-orositelia/",
              isNested: false,
            },
            {
              title: "Важное",
              url: "",
              isNested: true,
              isNestedAsList: true,
              itemsNested: [
                {
                  title: "Проектировщикам",
                  url: "/proektirovshchikam/",
                },
                {
                  title: "Дилерам",
                  url: "/shop/dileram/",
                },
                {
                  title: "Доставка",
                  url: "/shop/dostavka/",
                },
              ],
            },
          ]}
        />
        <NavigationLink title="КОНТАКТЫ" url="contacts" additionalLinks={[]} />
      </ul>
    </>
  );
};
