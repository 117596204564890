import { useLocation } from "react-router";
import "../ItemPage.scss";
import { FormEvent, useEffect, useRef, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { FullMerchandiseItemInfo } from "../../../../types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useActions } from "../../../../hooks/useActions";
import { MerchandiseItemInfoAdmin } from "./MerchandiseItemInfoAdmin";
import axios from "axios";
import { serverUrl } from "../../../../config";

export const MerchandiseItemPageAdmin = () => {
  const url = useLocation();
  const { data, error, state } = useFetch<FullMerchandiseItemInfo[]>(
    `${serverUrl}shop/${url.pathname.split("/")[2]}`
  );

  const isLoginSuccess = useSelector(
    (state: RootState) => state.loginReducer.isLoginSuccessful
  );

  const itemsArray = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );
  const [inputValue, setInputValue] = useState(
    itemsArray.find((e) => e.item_key === parseInt(url.pathname.split("/")[2]))
      ?.cartItemsCount || 0
  );
  const [displayEditMenu, setDisplayEditMenu] = useState(false);

  const { setCartState, setEditMenuState } = useActions();

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const addItemToCart = () => {
    /* setCartItems(info as MerchandiseItemInfo); */
    console.log(itemsArray);
    if (data && itemsArray.find((e) => e.item_key === data[0]?.item_key)) {
      const foundItemIndex = itemsArray.findIndex(
        (e) => e.item_key === data[0]?.item_key
      );
      setCartState(
        itemsArray.map((e, i) => {
          if (i == foundItemIndex) {
            return { ...e, cartItemsCount: e.cartItemsCount + inputValue };
          }
          return e;
        })
      );
    } else {
      if (data) {
        setCartState([
          ...itemsArray,
          { ...data[0], cartItemsCount: inputValue },
        ]);
      }
    }
  };

  useEffect(() => {
    if (data) {
      console.log(data[0]);
      console.log(isLoginSuccess);
    }
  }, [data]);

  const loginAsAdmin = async (e: FormEvent) => {
    e.preventDefault();
    const response = await axios.post(serverUrl + "admin-login", {
      login: usernameInputRef.current?.value,
      password: passwordInputRef.current?.value,
    });
    console.log(response.data);
    localStorage.setItem("token", JSON.stringify(response.data.token));
    window.location.reload();
  };

  return (
    <div className="merchandise_page_wrapper">
      <section className="merchandise_page_content">
        {isLoginSuccess ? (
          <>
            <span className="merchandise_page_navigation">
              <Link to="/">Главная</Link> / <Link to="/shop">Продукция</Link> /
              <Link to="/">
                {data ? data[0].item_category : "Категория товара"}
              </Link>
              /<a>{data ? data[0].item_title : "Муфта"}</a>
            </span>
            {data ? (
              <div className="merchandise_page">
                <div className="merchandise_menu">
                  <div className="merchandise_img_wrapper">
                    <img
                      className="merchandise_img"
                      src={data[0]?.item_img}
                      alt={data[0].item_title}
                    ></img>
                    <p
                      className="edit_button"
                      onClick={() => {
                        setEditMenuState({
                          url: `${serverUrl}img/${data[0].item_key}`,
                          propertiesArray: [
                            {
                              title: "Ссылка на картинку",
                              initialValue: data[0].item_img,
                              propertyName: "img",
                              type: "text",
                              dataType: "string",
                            },
                          ],
                        });
                        setDisplayEditMenu(true);
                      }}
                    >
                      Изменить
                    </p>
                  </div>
                  <div className="merchandise_block">
                    <h1 className="block_main_heading">
                      {data[0]?.item_title}{" "}
                      <svg
                        onClick={() => {
                          setEditMenuState({
                            url: `${serverUrl}title/${data[0].item_key}`,
                            propertiesArray: [
                              {
                                title: "Название продукта",
                                initialValue: data[0].item_title,
                                propertyName: "item_title",
                                type: "text",
                                dataType: "string",
                              },
                            ],
                          });
                          setDisplayEditMenu(true);
                        }}
                        fill="#000000"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 494.936 494.936"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path>{" "}
                              <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </h1>
                    <div className="block_underline"></div>
                    <div className="star_rating_wrapper">
                      <div className="star_rating">
                        <div
                          className="star_rating_active"
                          style={{
                            width: `${
                              29 *
                                (data[0]?.item_reviews?.reviews?.reduce(
                                  (prev, curr) => {
                                    return prev + curr.rating;
                                  },
                                  0
                                ) /
                                  data[0]?.item_reviews?.reviews?.length) || 0
                            }px`,
                          }}
                        >
                          {Array(5)
                            .fill(null)
                            .map((e, i) => (
                              <svg
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 47.94 47.94"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>{" "}
                                </g>
                              </svg>
                            ))}
                        </div>
                        {Array(5)
                          .fill(null)
                          .map((e, i) => (
                            <svg
                              fill="#000000"
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 36.09 36.09"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g>
                                  {" "}
                                  <path d="M36.042,13.909c-0.123-0.377-0.456-0.646-0.85-0.688l-11.549-1.172L18.96,1.43c-0.16-0.36-0.519-0.596-0.915-0.596 s-0.755,0.234-0.915,0.598L12.446,12.05L0.899,13.221c-0.394,0.04-0.728,0.312-0.85,0.688c-0.123,0.377-0.011,0.791,0.285,1.055 l8.652,7.738L6.533,34.045c-0.083,0.387,0.069,0.787,0.39,1.02c0.175,0.127,0.381,0.191,0.588,0.191 c0.173,0,0.347-0.045,0.503-0.137l10.032-5.84l10.03,5.84c0.342,0.197,0.77,0.178,1.091-0.059c0.32-0.229,0.474-0.633,0.391-1.02 l-2.453-11.344l8.653-7.737C36.052,14.699,36.165,14.285,36.042,13.909z M25.336,21.598c-0.268,0.24-0.387,0.605-0.311,0.957 l2.097,9.695l-8.574-4.99c-0.311-0.182-0.695-0.182-1.006,0l-8.576,4.99l2.097-9.695c0.076-0.352-0.043-0.717-0.311-0.957 l-7.396-6.613l9.87-1.002c0.358-0.035,0.668-0.264,0.814-0.592l4.004-9.077l4.003,9.077c0.146,0.328,0.456,0.557,0.814,0.592 l9.87,1.002L25.336,21.598z"></path>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          ))}
                      </div>
                      <p>
                        ({data[0]?.item_reviews?.reviews?.length || 0} отзывов
                        клиентов)
                      </p>
                    </div>
                    <div className="block_item_cost">
                      <h2>₽{data[0]?.item_cost}</h2>
                      <svg
                        onClick={() => {
                          setEditMenuState({
                            url: `${serverUrl}cost/${data[0].item_key}`,
                            propertiesArray: [
                              {
                                title: "Цена товара",
                                initialValue: data[0].item_cost,
                                propertyName: "item_cost",
                                type: "text",
                                dataType: "number",
                              },
                            ],
                          });
                          setDisplayEditMenu(true);
                        }}
                        fill="#000000"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 494.936 494.936"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path>{" "}
                              <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="block_underline"></div>
                    <div className="block_item_buttons">
                      <h2>Шт:</h2>
                      <button
                        className="count_change_button"
                        onClick={() => {
                          setInputValue((e) => e + 1);
                        }}
                      >
                        +
                      </button>
                      <input
                        type="text"
                        value={inputValue || 0}
                        onChange={(e) =>
                          setInputValue(parseInt(e.target.value) || 0)
                        }
                      />
                      <button
                        className="count_change_button"
                        onClick={() => {
                          if (inputValue - 1 >= 0) {
                            setInputValue((e) => e - 1);
                          }
                        }}
                      >
                        -
                      </button>
                      <button
                        className="add_to_cart_button"
                        onClick={addItemToCart}
                      >
                        В КОРЗИНУ
                      </button>
                    </div>
                    <div className="block_underline"></div>
                    <div className="block_item_property">
                      <h2>Артикул:</h2>
                      {data[0]?.item_vendor_code}{" "}
                      <svg
                        onClick={() => {
                          setEditMenuState({
                            url: `${serverUrl}vendor_code/${data[0].item_key}`,
                            propertiesArray: [
                              {
                                title: "Артикул",
                                initialValue: data[0].item_vendor_code,
                                propertyName: "item_vendor_code",
                                type: "text",
                                dataType: "string",
                              },
                            ],
                          });
                          setDisplayEditMenu(true);
                        }}
                        width="24px"
                        fill="#000000"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 494.936 494.936"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path>{" "}
                              <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="block_item_property">
                      <h2>Категория:</h2>
                      {data[0]?.item_category}
                      <svg
                        onClick={() => {
                          setEditMenuState({
                            url: `${serverUrl}category/${data[0].item_key}`,
                            propertiesArray: [
                              {
                                title: "Категория товара",
                                initialValue: data[0].item_category,
                                propertyName: "item_category",
                                type: "text",
                                dataType: "string",
                              },
                            ],
                          });
                          setDisplayEditMenu(true);
                        }}
                        width="24px"
                        fill="#000000"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 494.936 494.936"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path>{" "}
                              <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path>{" "}
                            </g>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <MerchandiseItemInfoAdmin
                  info={data[0]}
                  titlesArray={[
                    "характеристики",
                    "описание",
                    "видео",
                    "документы",
                    `отзывы (${data[0]?.item_reviews?.reviews?.length || 0})`,
                  ]}
                  displayEditMenu={displayEditMenu}
                  setDisplayEditMenu={setDisplayEditMenu}
                />
              </div>
            ) : (
              <div className="merchandise_page"></div>
            )}
          </>
        ) : !isLoginSuccess && localStorage.getItem("token") ? (
          <div className="loading_background">
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <form className="auth_form" onSubmit={(e) => loginAsAdmin(e)}>
            <img
              src="https://ajax-group.ru/wp-content/uploads/2022/07/logo-90-2.png"
              alt=""
              className="auth_logo"
            />
            <input
              ref={usernameInputRef}
              type="text"
              name="Логин"
              id=""
              placeholder="Логин"
              className="auth_form_field"
            />
            <input
              ref={passwordInputRef}
              type="text"
              name="Пароль"
              id=""
              placeholder="Пароль"
              className="auth_form_field"
            />
            <button className="submit_button">Войти</button>
          </form>
        )}
      </section>
    </div>
  );
};
