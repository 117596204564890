import { ArrowButton } from "../Header/NavigationFull/ArrowButton";
import "./Burger.scss";
import { FC } from "react";
import BurgerSection from "./BurgerSection";

interface Props {
  burgerMenuState: "idle" | "active" | "unactive";
  setIsBurgerMenuActive: React.Dispatch<
    React.SetStateAction<"idle" | "active" | "unactive">
  >;
}

const Burger: FC<Props> = ({ setIsBurgerMenuActive, burgerMenuState }) => {
  return (
    <>
      {burgerMenuState != "idle" ? (
        <div
          className={
            burgerMenuState == "active"
              ? "burger_menu-active"
              : "burger_menu-unactive"
          }
        >
          <div className="burger_menu_top">
            <h3>Меню</h3>
            <svg
              onClick={() => setIsBurgerMenuActive("unactive")}
              className="burger_menu_close-btn"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
            </svg>
          </div>

          <div className="burger_menu_links">
            <BurgerSection sectionTitle="Главная" />
            <BurgerSection
              links={[
                {
                  title: "Сертификаты и лицензии",
                  url: "https://ajax-group.ru/about/sertifikaty/",
                  isNested: false,
                },
                {
                  title: "Выполненные объекты",
                  url: "https://ajax-group.ru/about/vypolnennye-obekty",
                  isNested: false,
                },
                {
                  title: "Наши партнеры",
                  url: "https://ajax-group.ru/about/nashi-partnery/",
                },
                {
                  title: "Новости",
                  url: "https://ajax-group.ru/about/news/",
                },
              ]}
              sectionTitle="О компании"
            />
            <BurgerSection
              links={[
                {
                  title: "Проектирование",
                  url: "https://ajax-group.ru/uslugi/proektirovanie/",
                  isNested: true,
                  itemsNested: [
                    {
                      title: "АУПТ",
                      url: "https://ajax-group.ru/uslugi/proektirovanie/aupt/",
                    },
                    {
                      title: "ВПВ",
                      url: "https://ajax-group.ru/uslugi/proektirovanie/vpv/",
                    },
                    {
                      title: "АПС",
                      url: "https://ajax-group.ru/uslugi/proektirovanie/vpv/",
                    },
                    {
                      title: "СОУЭ",
                      url: "https://ajax-group.ru/uslugi/proektirovanie/soue/",
                    },
                    {
                      title: "АППЗ",
                      url: "https://ajax-group.ru/uslugi/proektirovanie/appz/",
                    },
                  ],
                },
                {
                  title: "Монтажные работы АУПТ, ВПВ",
                  url: "https://ajax-group.ru/uslugi/montazhnye-raboty-aupt-vpv/",
                  isNested: false,
                },
                {
                  title: "Электромонтажные работы ЭОМ, ЭС, СС",
                  url: "https://ajax-group.ru/uslugi/elektromontazhnye-raboty-eom-es-ss/",
                },
                {
                  title: "Ливневая канализация",
                  url: "https://ajax-group.ru/uslugi/livnevaia-kanalizatciia/",
                },
                {
                  title: "Эксплуатация",
                  url: "https://ajax-group.ru/uslugi/ekspluatatciia/",
                },
              ]}
              sectionTitle="Услуги"
            ></BurgerSection>
            <BurgerSection
              sectionTitle="Продукция"
              links={[
                {
                  title: "Муфта спринклерная",
                  url: "https://ajax-group.ru/",
                  isNested: false,
                },
                {
                  title: "Защита оросителя",
                  url: "https://ajax-group.ru/shop/zashchita-orositelia/",
                  isNested: false,
                },
                {
                  title: "Важное",
                  url: "https://ajax-group.ru/shop/zashchita-orositelia/",
                  isNested: true,
                  itemsNested: [
                    {
                      title: "Проектировщикам",
                      url: "https://ajax-group.ru/proektirovshchikam/",
                    },
                    {
                      title: "Дилерам",
                      url: "https://ajax-group.ru/shop/dileram/",
                    },
                    {
                      title: "Доставка",
                      url: "https://ajax-group.ru/shop/dostavka/",
                    },
                  ],
                },
              ]}
            ></BurgerSection>
            <ul>
              <span className="burger_menu_link">Контакты</span>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Burger;
