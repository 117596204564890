import { FormEvent, useEffect, useRef, useState } from "react";
import "./OrderPage.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "../../../config";

export const OrderPage = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const [selectedMerchandise, setSelectedMerchandise] = useState<string[]>([]);

  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const cartItems = useSelector(
    (state: RootState) => state.cartReducer.itemsArray
  );

  const formRef = useRef<HTMLFormElement>(null);

  const phoneInputRef = useRef<HTMLInputElement>(null);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const nameInputRef = useRef<HTMLInputElement>(null);

  const noteInputRef = useRef<HTMLTextAreaElement>(null);

  const taxNumberInputRef = useRef<HTMLInputElement>(null);

  const companyNameInputRef = useRef<HTMLInputElement>(null);

  const [dealerAgreement, setDealerAgreement] = useState("Да");

  const [uploadedAccountDetails, setUploadedAccountDetails] = useState<File[]>(
    []
  );
  const [uploadedAdditionalFiles, setUploadedAdditionalFiles] = useState<
    File[]
  >([]);

  const [isLegalEntity, setIsLegalEntity] = useState("Нет");

  const accountDetailsInputRef = useRef<HTMLInputElement>(null);
  const additionalFilesInputRef = useRef<HTMLInputElement>(null);

  const handleSendRequest = async () => {
    const fr = new FileReader();

    const error: string[] = [];

    if (phoneInputRef.current && !phoneInputRef.current.value) {
      error.push("Поле телефон должно быть заполнено");
    }
    if (nameInputRef.current && !nameInputRef.current.value) {
      error.push("Поле имя должно быть заполнено");
    }
    if (emailInputRef.current && !emailInputRef.current.value) {
      error.push("Поле Email должно быть заполнено");
    }
    if (error.length > 0) {
      setErrorMessage(error);
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (accountDetailsInputRef.current?.files) {
      console.log(accountDetailsInputRef.current?.files[0]);
    }
    if (
      phoneInputRef.current?.value &&
      nameInputRef.current?.value &&
      emailInputRef.current?.value &&
      (noteInputRef.current?.textContent ||
        noteInputRef.current?.textContent?.length == 0)
    ) {
      var subjectStr = "";
      if (selectedOptions.length == 1 && selectedOptions.includes("услуга")) {
        subjectStr = "Заявка";
      } else if (
        selectedOptions.length == 1 &&
        selectedOptions.includes("товар")
      ) {
        subjectStr = "Покупка";
      } else if (
        selectedOptions.includes("товар") &&
        selectedOptions.includes("услуга")
      ) {
        subjectStr = "Смешанный заказ";
      } else {
        subjectStr = "Покупка";
      }

      const data = {
        subject: subjectStr,
        phone: phoneInputRef.current.value,
        name: nameInputRef.current.value,
        email: emailInputRef.current.value,
        note: noteInputRef.current.value,
        services: selectedServices,
        merchandise: cartItems.length > 0 ? cartItems : selectedMerchandise,
        dealer_agreement: dealerAgreement,
        is_legal_entity: isLegalEntity,
        tax_number: taxNumberInputRef?.current?.value,
        company_name: companyNameInputRef?.current?.value,
      };

      const response = await axios.post(serverUrl + "post-order", data);
      console.log(response);
    }
  };

  return (
    <div className="order_page_wrapper">
      <h1 className="page_heading">ОФОРМЛЕНИЕ ЗАКАЗА</h1>
      {errorMessage.length > 0 ? (
        <div className="errors_wrapper">
          {errorMessage.map((e) => (
            <div className="error_item">
              {e}{" "}
              <div>
                <svg
                  onClick={() => {
                    setErrorMessage(
                      errorMessage.filter((el, i) => {
                        return el != e;
                      })
                    );
                  }}
                  fill="#000000"
                  height="64px"
                  width="64px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 490 490"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
                  </g>
                </svg>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      <form className="checkout_form_wrapper" ref={formRef}>
        <div className="checkout_form">
          <div className="checkout_form_content_wrapper">
            <h2>ДЕТАЛИ ОПЛАТЫ</h2>
            <div className="form_inputs">
              <div className="input_wrapper">
                <div className="input_title">
                  Вы хотите стать нашим официальным дилером?
                </div>
                <div className="radio_wrapper">
                  <label htmlFor="dealer1" className="option_wrapper">
                    <input
                      type="radio"
                      id="dealer1"
                      name="dealer"
                      value="Да"
                      radioGroup="1"
                      defaultChecked={true}
                      className="radio_input"
                      onChange={(e) => setDealerAgreement(e.target.value)}
                    />
                    <span className="custom_radio"></span>
                    <span className="radio_label">Да</span>
                  </label>
                  <label htmlFor="dealer2" className="option_wrapper">
                    <input
                      type="radio"
                      id="dealer2"
                      name="dealer"
                      value="Нет"
                      radioGroup="1"
                      className="radio_input"
                      onChange={(e) => setDealerAgreement(e.target.value)}
                    />
                    <span className="custom_radio"></span>
                    <span className="radio_label">Нет</span>
                  </label>
                </div>
              </div>
              {cartItems.reduce((prev, curr) => {
                return prev + curr.cartItemsCount;
              }, 0) == 0 ? (
                <div className="input_wrapper">
                  <div className="input_title">
                    Вы хотите заказать <abbr title="Обязательно">*</abbr>
                  </div>
                  <div className="checkbox_wrapper">
                    <label htmlFor="option1" className="option_wrapper">
                      <input
                        type="checkbox"
                        id="option1"
                        name="option"
                        value="товар"
                        radioGroup="2"
                        className="radio_input"
                        onChange={(event) =>
                          event.target.checked
                            ? setSelectedOptions([
                                ...selectedOptions,
                                event.target.value,
                              ])
                            : setSelectedOptions(
                                selectedOptions.filter((e, i) => {
                                  return e != event.target.value.toLowerCase();
                                })
                              )
                        }
                      />
                      <span className="custom_radio"></span>
                      <span className="radio_label">Товар</span>
                    </label>
                    <label htmlFor="option2" className="option_wrapper">
                      <input
                        type="checkbox"
                        id="option2"
                        name="option"
                        value="услуга"
                        radioGroup="2"
                        className="radio_input"
                        onChange={(event) =>
                          event.target.checked
                            ? setSelectedOptions([
                                ...selectedOptions,
                                event.target.value.toLowerCase(),
                              ])
                            : setSelectedOptions(
                                selectedOptions.filter((e, i) => {
                                  return e != event.target.value.toLowerCase();
                                })
                              )
                        }
                      />
                      <span className="custom_radio"></span>
                      <span className="radio_label">Услугу</span>
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {
                <>
                  {selectedOptions.includes("товар") ? (
                    <>
                      <div className="input_wrapper">
                        <div className="input_title">Выберите товар</div>
                        <div className="checkbox_wrapper">
                          <label
                            htmlFor="merchandise1"
                            className="option_wrapper"
                          >
                            <input
                              type="checkbox"
                              id="merchandise1"
                              name="merchandise"
                              value="муфта"
                              radioGroup="2"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedMerchandise([
                                      ...selectedMerchandise,
                                      event.target.value,
                                    ])
                                  : setSelectedMerchandise(
                                      selectedMerchandise.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">
                              Муфта спринклерная
                            </span>
                          </label>
                          <label
                            htmlFor="merchandise2"
                            className="option_wrapper"
                          >
                            <input
                              type="checkbox"
                              id="merchandise2"
                              name="merchandise"
                              value="защита оросителя"
                              radioGroup="2"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedMerchandise([
                                      ...selectedMerchandise,
                                      event.target.value.toLowerCase(),
                                    ])
                                  : setSelectedMerchandise(
                                      selectedMerchandise.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">
                              Защита оросителя
                            </span>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {selectedOptions.includes("услуга") ? (
                    <>
                      <div className="input_wrapper">
                        <div className="input_title">Выберите услугу</div>
                        <div className="checkbox_wrapper">
                          <label htmlFor="service1" className="option_wrapper">
                            <input
                              type="checkbox"
                              id="service1"
                              name="service"
                              value="АУПТ"
                              radioGroup="3"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedServices([
                                      ...selectedServices,
                                      event.target.value,
                                    ])
                                  : setSelectedServices(
                                      selectedServices.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">АУПТ</span>
                          </label>
                          <label htmlFor="service2" className="option_wrapper">
                            <input
                              type="checkbox"
                              id="service2"
                              name="service"
                              value="ВПВ"
                              radioGroup="3"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedServices([
                                      ...selectedServices,
                                      event.target.value,
                                    ])
                                  : setSelectedServices(
                                      selectedServices.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">ВПВ</span>
                          </label>
                          <label htmlFor="service3" className="option_wrapper">
                            <input
                              type="checkbox"
                              id="service3"
                              name="service"
                              value="АПС"
                              radioGroup="3"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedServices([
                                      ...selectedServices,
                                      event.target.value,
                                    ])
                                  : setSelectedServices(
                                      selectedServices.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">АПС</span>
                          </label>
                          <label htmlFor="service4" className="option_wrapper">
                            <input
                              type="checkbox"
                              id="service4"
                              name="service"
                              value="СОУЭ"
                              radioGroup="3"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedServices([
                                      ...selectedServices,
                                      event.target.value,
                                    ])
                                  : setSelectedServices(
                                      selectedServices.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">СОУЭ</span>
                          </label>
                          <label htmlFor="service5" className="option_wrapper">
                            <input
                              type="checkbox"
                              id="service5"
                              name="service"
                              value="АППЗ"
                              radioGroup="3"
                              className="radio_input"
                              onChange={(event) =>
                                event.target.checked
                                  ? setSelectedServices([
                                      ...selectedServices,
                                      event.target.value,
                                    ])
                                  : setSelectedServices(
                                      selectedServices.filter((e, i) => {
                                        return (
                                          e != event.target.value.toLowerCase()
                                        );
                                      })
                                    )
                              }
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">АППЗ</span>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {selectedOptions.length > 0 ||
                  cartItems.reduce((prev, curr) => {
                    return prev + curr.cartItemsCount;
                  }, 0) > 0 ? (
                    <>
                      <div className="input_wrapper">
                        <div className="input_title">Вы юридическое лицо?</div>
                        <div className="radio_wrapper">
                          <label htmlFor="entity1" className="option_wrapper">
                            <input
                              type="radio"
                              id="entity1"
                              name="entity"
                              value="Да"
                              radioGroup="1"
                              className="radio_input"
                              onChange={(e) => setIsLegalEntity(e.target.value)}
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">Да</span>
                          </label>
                          <label htmlFor="entity2" className="option_wrapper">
                            <input
                              type="radio"
                              id="entity2"
                              name="entity"
                              value="Нет"
                              radioGroup="1"
                              className="radio_input"
                              onChange={(e) => setIsLegalEntity(e.target.value)}
                              defaultChecked
                            />
                            <span className="custom_radio"></span>
                            <span className="radio_label">Нет</span>
                          </label>
                        </div>
                      </div>
                      <div className="input_wrapper">
                        <div className="input_title">
                          Телефон <abbr title="Обязательно">*</abbr>
                        </div>
                        <input
                          type="text"
                          className="text_input"
                          ref={phoneInputRef}
                        />
                      </div>
                      <div className="input_wrapper">
                        <div className="input_title">
                          Email <abbr title="Обязательно">*</abbr>
                        </div>
                        <input
                          type="text"
                          className="text_input"
                          ref={emailInputRef}
                        />
                      </div>
                      <div className="input_wrapper">
                        <div className="input_title">
                          Имя <abbr title="Обязательно">*</abbr>
                        </div>
                        <input
                          type="text"
                          className="text_input"
                          ref={nameInputRef}
                        />
                      </div>
                      {isLegalEntity.toLowerCase() == "да" ? (
                        <>
                          <div className="input_wrapper">
                            <div className="input_title">ИНН</div>
                            <input
                              type="text"
                              className="text_input"
                              ref={taxNumberInputRef}
                            />
                          </div>
                          <div className="input_wrapper">
                            <div className="input_title">Название компании</div>
                            <input
                              type="text"
                              className="text_input"
                              ref={companyNameInputRef}
                            />
                          </div>
                          <div className="input_wrapper">
                            <div className="input_title">Реквизиты</div>
                            <input
                              type="file"
                              className="file_input"
                              multiple={true}
                              ref={accountDetailsInputRef}
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              onInput={() => {
                                if (accountDetailsInputRef.current?.files) {
                                  setUploadedAccountDetails(
                                    Array.from(
                                      accountDetailsInputRef.current.files
                                    )
                                  );
                                }
                              }}
                            />
                            <button
                              className="file_input_button"
                              type="button"
                              onClick={(e) => {
                                if (accountDetailsInputRef.current) {
                                  accountDetailsInputRef.current.click();
                                }
                              }}
                            >
                              загрузить
                            </button>
                            {accountDetailsInputRef.current?.files &&
                            accountDetailsInputRef.current?.files?.length >
                              0 ? (
                              <div className="file_input_files">
                                {Array.from(uploadedAccountDetails).map(
                                  (e, i) => (
                                    <div>
                                      <img
                                        src="https://ajax-group.ru/wp-includes/images/media/interactive.png"
                                        alt=""
                                      />
                                      <p>{e.name}</p>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }
            </div>
          </div>
        </div>
        <div className="checkout_note">
          <div className="note_wrapper">
            <h3>Примечание к заказу</h3>
            <textarea
              name=""
              id=""
              placeholder="Примечания к вашему заказу, например, особые пожелания отделу доставки."
              ref={noteInputRef}
            ></textarea>
            <div className="input_wrapper">
              <div className="input_title">Доп. файл(например Т.З.)</div>
              <input
                type="file"
                className="file_input"
                multiple={true}
                ref={additionalFilesInputRef}
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onInput={() => {
                  if (additionalFilesInputRef.current?.files) {
                    setUploadedAdditionalFiles(
                      Array.from(additionalFilesInputRef.current.files)
                    );
                  }
                }}
              />
              <button
                className="file_input_button"
                type="button"
                onClick={(e) => {
                  if (additionalFilesInputRef.current) {
                    additionalFilesInputRef.current.click();
                  }
                }}
              >
                загрузить
              </button>
              {additionalFilesInputRef.current?.files &&
              additionalFilesInputRef.current?.files?.length > 0 ? (
                <div className="file_input_files">
                  {Array.from(uploadedAdditionalFiles).map((e, i) => (
                    <div>
                      <img
                        src="https://ajax-group.ru/wp-includes/images/media/interactive.png"
                        alt=""
                      />
                      <p>{e.name}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="checkout_cart_wrapper">
        <h2>ВАШ ЗАКАЗ</h2>
        <div className="checkout_cart">
          <table>
            <thead>
              <tr>
                <th className="item_title">ТОВАР</th>
                <th className="item_count">КОЛИЧЕСТВО</th>
                <th className="item_total">ПОДЫТОГ</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((e, i) => (
                <tr>
                  <td className="item_title">{e.item_title}</td>
                  <td className="item_count">
                    <span>
                      {e.cartItemsCount} × {e.item_cost}₽
                    </span>
                  </td>
                  <th className="item_total">
                    {e.item_cost * e.cartItemsCount}₽
                  </th>
                </tr>
              ))}
              <tr>
                <th className="item_title">Итого</th>
                <th className="item_count"></th>
                <th className="item_total">
                  {cartItems.reduce((prev, curr) => {
                    return prev + curr.item_cost * curr.cartItemsCount;
                  }, 0)}
                  ₽
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="privacy_warning">
        Ваши личные данные будут использоваться для обработки вашего заказа,
        поддержки вашего опыта на этом веб-сайте и для других целей, описанных
        на странице{" "}
        <Link to="privacy-policy">политика конфиденциальности.</Link>
      </div>
      <div className="submit_button_wrapper">
        <button className="submit_button" onClick={handleSendRequest}>
          подтвердить заказ
        </button>
      </div>
    </div>
  );
};
