import { FC } from "react";

interface Props {
  itemImg: string;
  itemTitle: string;
  itemUrl: string;
}

export const PartnersItem: FC<Props> = ({ itemImg, itemTitle, itemUrl }) => {
  return (
    <div className="partners_item_wrapper">
      <span className="partners_item_shadow">{itemTitle.toUpperCase()}</span>
      <a href={itemUrl} className="partners_link">
        <img src={itemImg} alt={itemTitle} />
      </a>
    </div>
  );
};
