import "./CompletedContracts.scss";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { CompletedContractsSlide } from "./CompletedContractsSlide";
import { CompletedContractsIndicator } from "./CompletedContractsIndicator";
import { TitleUnderline } from "../../TitileUndeline";
import useFetch from "../../../../hooks/useFetch";
import { serverUrl } from "../../../../config";
import { ContractBanner, ContractBanners } from "../../../../types";
export const CompletedContracts = () => {
  const [animateSlider, setAnimateSlider] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const slidesRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<any>(null);
  const [sliderStyles, setSliderStyles] = useState<React.CSSProperties>({});

  const { data } = useFetch<ContractBanners>(serverUrl + "contract_banners");

  const localBanners = JSON.parse(
    localStorage.getItem("contract_banners_local") || "[]"
  );

  /*   useLayoutEffect(() => {
    setSliderStyles({
      transform: `translateX(${
        (100 / (slidesRef.current as HTMLDivElement)?.children.length) *
        -slideIndex
      }%)`,
      transition: "transform 0.3s",
    });
  }, []); */

  const startSliderInterval = () => {
    console.log(slideIndex);
    setSlideIndex((prev) => {
      if (prev + 1 < (slidesRef.current as HTMLDivElement).children.length) {
        return prev + 1;
      } else return prev;
    });
  };

  console.log(localBanners);

  useEffect(() => {
    intervalRef.current = setInterval(startSliderInterval, 5000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    setSliderStyles({
      transform: `translateX(${
        (100 / (slidesRef.current as HTMLDivElement)?.children.length) *
        -slideIndex
      }%)`,
    });
    /* timeoutRef.current = setTimeout(() => {
      setSlideIndex(slideIndex + 1);
    }, 5000); */
    console.log(slideIndex);
  }, [slideIndex]);

  useEffect(() => {
    if (data) {
      localStorage.setItem("contract_banners_local", JSON.stringify(data));
    }
  }, [data]);

  return (
    <div className="completed_contracts_wrapper">
      <h1>ВЫПОЛНЕННЫЕ ОБЪЕКТЫ</h1>
      <TitleUnderline></TitleUnderline>
      <div className="completed_contracts_container">
        <button
          className="completed_contracts_button left"
          onClick={() => {
            if (slideIndex - 1 > -1) {
              setSlideIndex(slideIndex - 1);
              clearInterval(intervalRef.current);
              console.log(slideIndex);
            }
          }}
        >
          ‹
        </button>
        <div className="completed_contracts_slides_wrapper">
          <div
            ref={slidesRef}
            style={sliderStyles}
            className="completed_contracts_slides"
          >
            {data
              ? data.map((e: ContractBanner, i: number) => (
                  <CompletedContractsSlide
                    key={i}
                    imageUrl={e.img}
                    description={e.title}
                    slideLink={e.url}
                  />
                ))
              : localBanners.map((e: ContractBanner, i: number) => (
                  <CompletedContractsSlide
                    key={i}
                    imageUrl={e.img}
                    description={e.title}
                    slideLink={e.url}
                  />
                ))}
          </div>
        </div>
        <button
          className="completed_contracts_button right"
          onClick={() => {
            if (
              slideIndex + 1 <
              (slidesRef.current as HTMLDivElement).children.length
            ) {
              setSlideIndex(slideIndex + 1);
              clearInterval(intervalRef.current);
              console.log(slideIndex);
            }
          }}
        >
          ›
        </button>
        <CompletedContractsIndicator
          slideIndex={slideIndex}
          slides={Array.from(
            (slidesRef.current as HTMLDivElement)?.children || []
          )}
          setSlideIndex={setSlideIndex}
        />
      </div>
    </div>
  );
};
