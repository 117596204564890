import { FC, useRef, useState, useEffect } from "react";
import { FullMerchandiseItemInfo } from "../../../../types";
import axios from "axios";
import { EditMenu } from "./EditMenu";
import { useActions } from "../../../../hooks/useActions";
import { PropertiesTableCell } from "./PropertiesTable/PropertiesTableCell";
import { serverUrl } from "../../../../config";

interface Props {
  titlesArray: string[];
  info: FullMerchandiseItemInfo;
  displayEditMenu: boolean;
  setDisplayEditMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MerchandiseItemInfoAdmin: FC<Props> = ({
  titlesArray,
  info,
  displayEditMenu,
  setDisplayEditMenu,
}) => {
  const { setEditMenuState } = useActions();
  const [selectedItemTitle, setSelectedItemTitle] = useState(
    localStorage.getItem("item_navigation") || titlesArray[0]
  );
  const [hoveredStarValue, setHoveredStarValue] = useState(-1);
  const [usernameValue, setUsernameValue] = useState("");
  const [userEmailValue, setUserEmailValue] = useState("");
  const reviewFieldRef = useRef<HTMLTextAreaElement>(null); //у textarea нет ивента onChange :(
  const [selectedStarValue, setSelectedStarValue] = useState<null | number>(
    null
  );
  const [propertiesTable, setPropertiesTable] = useState(
    info.item_properties_table
  );
  const [displayConfirmationMenu, setDisplayConfirmationMenu] = useState(false);

  console.log();

  useEffect(() => {
    localStorage.setItem("item_navigation", selectedItemTitle);
  }, [selectedItemTitle]);

  const getDate = (dateObj: string) => {
    let objectDate = new Date(dateObj);

    let day = objectDate.getDate();
    console.log(day);

    let month = objectDate.getMonth();
    console.log(month + 1);

    let year = objectDate.getFullYear();

    return `${day < 10 ? `0${day}` : day}.${month + 1}.${year}`;
  };

  const postReview = async (e: React.FormEvent<HTMLFormElement>) => {
    if (selectedStarValue) {
      const now = new Date();
      const requestBody: FullMerchandiseItemInfo["item_reviews"]["reviews"][0] =
        {
          text: reviewFieldRef.current?.value || "",
          rating: selectedStarValue,
          person_name: usernameValue || "Аноним",
          person_email: userEmailValue,
          timestamp: now.toISOString(),
        };
      const res = await axios.post(
        `${serverUrl}reviews/item/${info.item_key}`,
        requestBody
      );
    }
  };
  const deleteTableRow = async (rowIndex: number) => {
    const requestBody = {
      row_index: rowIndex,
    };

    const res = await axios.post(
      `${serverUrl}product-table/remove-row/${info.item_key}`,
      requestBody
    );
    /* setPropertiesTable({
      ...propertiesTable,
      rows: [
        ...propertiesTable.rows.filter((e, i) => {
          console.log(i, rowIndex);
          return i != rowIndex;
        }),
      ],
    }); */

    console.log(res);
    window.location.reload();
  };

  const deleteVideo = async (videoIndex: number) => {
    const requestBody = { video_index: videoIndex };

    const res = await axios.post(
      `${serverUrl}videos/remove-video/${info.item_key}`,
      requestBody
    );
    console.log(res);
    window.location.reload();
  };
  const deleteDocument = async (documentIndex: number) => {
    const requestBody = { document_index: documentIndex };

    const res = await axios.post(
      `${serverUrl}documents/remove-document/${info.item_key}`,
      requestBody
    );
    console.log(res);
    window.location.reload();
  };

  return (
    <div className="merchandise_page_info">
      {displayEditMenu ? (
        <EditMenu setDisplayEditMenu={setDisplayEditMenu} />
      ) : (
        <></>
      )}
      {/*       {displayConfirmationMenu ? (
        <div
          className="merchandise_context_menu"
          style={{ width: "400px", height: "300px" }}
        >
          <div
            className="merchandise_close_menu_button"
            onClick={() => setDisplayConfirmationMenu(false)}
          >
            <svg
              fill="#000000"
              height="200px"
              width="200px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 490 490"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon>{" "}
              </g>
            </svg>
          </div>
          <div className="context_menu_text">Точно удалить?</div>
          <button className="confirm_button" onClick={deleteTableRow}>
            Подтвердить
          </button>
        </div>
      ) : (
        <></>
      )} */}
      <div className="info_navigation">
        {titlesArray.map((e, i) => (
          <div
            className={
              e == selectedItemTitle
                ? "navigation_heading_wrapper active"
                : "navigation_heading_wrapper"
            }
            onClick={() => setSelectedItemTitle(e)}
          >
            <h3>{e.toUpperCase()}</h3>
            <div className="heading_underline"></div>
          </div>
        ))}
      </div>
      {selectedItemTitle.toLowerCase() == "характеристики" ? (
        <div className="info_content">
          <table className="info_features">
            <tbody>
              <tr>
                <th>Диаметр</th>
                <td>{info?.item_diameter}</td>
              </tr>
              <tr>
                <th>Резьба</th>
                <td>{info?.item_thread}</td>
              </tr>
              <tr>
                <th>Проточка</th>
                <td>{info?.item_groove ? "да" : "нет"}</td>
              </tr>
              <tr>
                <th>Вес упаковки</th>
                <td>{info?.item_package_weight} кг</td>
              </tr>
              <tr>
                <th>Длина изделия</th>
                <td>{info?.item_length} мм</td>
              </tr>
              <tr>
                <th>Вес</th>
                <td>{info?.item_weight} гр</td>
              </tr>
              <tr>
                <th>Кол-во в упаковке</th>
                <td>{info?.items_per_package} шт</td>
              </tr>
            </tbody>
          </table>
          <span
            className="edit_button"
            onClick={() => {
              setEditMenuState({
                url: `${serverUrl}features-table/${info.item_key}`,
                propertiesArray: [
                  {
                    title: "Диаметр",
                    propertyName: "item_diameter",
                    initialValue: info.item_diameter,
                    type: "text",
                    dataType: "string",
                  },
                  {
                    title: "Резьба",
                    propertyName: "item_thread",
                    initialValue: info.item_thread,
                    type: "text",
                    dataType: "string",
                  },
                  {
                    title: "Проточка",
                    propertyName: "item_groove",
                    initialValue: info.item_groove,
                    type: "checkbox",
                    dataType: "number",
                  },
                  {
                    title: "Вес упаковки",
                    propertyName: "item_package_weight",
                    initialValue: info.item_weight,
                    type: "text",
                    dataType: "string",
                  },
                  {
                    title: "Длина изделия",
                    propertyName: "item_length",
                    initialValue: info.item_length,
                    type: "text",
                    dataType: "string",
                  },
                  {
                    title: "Вес",
                    propertyName: "item_weight",
                    initialValue: info.item_weight,
                    type: "text",
                    dataType: "string",
                  },
                  {
                    title: "Кол-во в упаковке",
                    propertyName: "items_per_package",
                    initialValue: info.items_per_package,
                    type: "text",
                    dataType: "string",
                  },
                ],
              });
              setDisplayEditMenu(true);
            }}
          >
            Изменить
          </span>
        </div>
      ) : selectedItemTitle.toLowerCase() == "описание" ? (
        <div className="info_content">
          <div className="info_description" style={{ whiteSpace: "pre-line" }}>
            {/* {info.item_description.split(/\n/).map((e, i) => (
              <p>
                <br />
                {e.replace("\n", "")}
              </p>
            ))} */}
            <p>{info.item_description}</p>
          </div>
          <div className="info_blueprint">
            {info.item_blueprint ? (
              <>
                <img src={info.item_blueprint} alt="Чертеж муфты" />
                <p
                  className="edit_button"
                  onClick={() => {
                    setEditMenuState({
                      url: `${serverUrl}description/${info.item_key}`,
                      propertiesArray: [
                        {
                          title: "Описание",
                          initialValue: info.item_description,
                          propertyName: "item_description",
                          type: "textarea",
                          dataType: "string",
                        },
                        {
                          title: "Ссылка на чертеж",
                          initialValue: info.item_blueprint,
                          propertyName: "item_blueprint",
                          type: "text",
                          dataType: "string",
                        },
                      ],
                    });
                    setDisplayEditMenu(true);
                  }}
                >
                  Изменить
                </p>
              </>
            ) : (
              <>
                <button
                  className="add_blueprint_button"
                  onClick={() => {
                    setEditMenuState({
                      url: `${serverUrl}blueprint/${info.item_key}`,
                      propertiesArray: [
                        {
                          title: "Ссылка на чертеж",
                          initialValue: info.item_blueprint,
                          propertyName: "item_blueprint",
                          type: "text",
                          dataType: "string",
                        },
                      ],
                    });
                    setDisplayEditMenu(true);
                  }}
                >
                  <svg
                    fill="#000000"
                    height="200px"
                    width="200px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 455 455"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5 "></polygon>{" "}
                    </g>
                  </svg>
                </button>
                <p
                  className="edit_button"
                  onClick={() => {
                    setEditMenuState({
                      url: `${serverUrl}description/${info.item_key}`,
                      propertiesArray: [
                        {
                          title: "Описание",
                          initialValue: info.item_description,
                          propertyName: "item_description",
                          type: "textarea",
                          dataType: "string",
                        },
                        {
                          title: "Ссылка на чертеж",
                          initialValue: info.item_blueprint,
                          propertyName: "item_blueprint",
                          type: "text",
                          dataType: "string",
                        },
                      ],
                    });
                    setDisplayEditMenu(true);
                  }}
                >
                  Изменить
                </p>
              </>
            )}
          </div>
          <div className="info_features_table_container">
            <table className="product_table">
              <thead>
                <tr>
                  <th rowSpan={4} style={{ width: "100px" }}>
                    <strong>№&nbsp; п/п</strong>
                  </th>
                  <th rowSpan={4} style={{ width: "370px" }}>
                    <strong>Артикул</strong>
                  </th>
                  <th rowSpan={4} style={{ width: "150px" }}>
                    <strong>Условный проход, Ду</strong>
                  </th>
                  <th rowSpan={4}>
                    <strong>G, дюймы</strong>
                  </th>
                  <th colSpan={2}>
                    <strong>Длина, мм </strong>
                  </th>
                  <th colSpan={2}>
                    <strong>Толщина стенки, мм</strong>
                  </th>
                  <th rowSpan={3}>
                    <strong>Диаметр выборки, мм</strong>
                  </th>
                  <th rowSpan={2}>
                    <strong>Масса, кг</strong>
                  </th>
                  <th rowSpan={4} style={{ width: "320px" }}>
                    <strong>Резьба</strong>
                  </th>
                  <th rowSpan={4}>
                    <strong>Фаска внутренняя, со стороны резьбы</strong>
                  </th>
                </tr>
                <tr>
                  <th>
                    <strong>L</strong>
                  </th>
                  <th>
                    <strong>L2</strong>
                  </th>
                  <th>
                    <strong>S</strong>
                  </th>
                  <th>
                    <strong>S2</strong>
                  </th>
                </tr>
                <tr>
                  <th colSpan={4}>
                    <strong>Допустимо отклонение +/- </strong>
                  </th>
                  <th>
                    <strong>Допустимо отклонение +/- </strong>
                  </th>
                </tr>
                <tr>
                  <PropertiesTableCell
                    propertiesTable={propertiesTable}
                    setPropertiesTable={setPropertiesTable}
                    value={propertiesTable?.deviation?.l}
                    isHeader={true}
                    propertyBeingEditedName="l"
                    postfix="мм"
                    dbKey={info.item_key}
                  />
                  <PropertiesTableCell
                    propertiesTable={propertiesTable}
                    setPropertiesTable={setPropertiesTable}
                    value={propertiesTable?.deviation?.l2}
                    isHeader={true}
                    propertyBeingEditedName="l2"
                    postfix="мм"
                    dbKey={info.item_key}
                  />
                  <PropertiesTableCell
                    propertiesTable={propertiesTable}
                    setPropertiesTable={setPropertiesTable}
                    value={propertiesTable?.deviation?.s}
                    isHeader={true}
                    propertyBeingEditedName="s"
                    postfix="%"
                    dbKey={info.item_key}
                  />
                  <PropertiesTableCell
                    propertiesTable={propertiesTable}
                    setPropertiesTable={setPropertiesTable}
                    value={propertiesTable?.deviation?.s2}
                    isHeader={true}
                    propertyBeingEditedName="s2"
                    postfix="%"
                    dbKey={info.item_key}
                  />
                  <th>
                    <strong>D</strong>
                  </th>
                  <PropertiesTableCell
                    propertiesTable={propertiesTable}
                    setPropertiesTable={setPropertiesTable}
                    value={propertiesTable?.deviation?.m}
                    isHeader={true}
                    propertyBeingEditedName="m"
                    postfix="%"
                    dbKey={info.item_key}
                  />
                </tr>
              </thead>
              <tbody>
                {propertiesTable?.rows?.map((e, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.vendor_code}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="vendor_code"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.nominal_diameter}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="nominal_diameter"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.groove_diameter}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="groove_diameter"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.l_length}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="l_length"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.l2_length}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="l2_length"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.s_wall_thickness}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="s_wall_thickness"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.s2_wall_thickness}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="s2_wall_thickness"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.sampling_diameter}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="sampling_diameter"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.weight}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="weight"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.thread}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="thread"
                      dbKey={info.item_key}
                    />
                    <PropertiesTableCell
                      propertiesTable={propertiesTable}
                      setPropertiesTable={setPropertiesTable}
                      value={e.inner_chamfer}
                      isHeader={false}
                      rowIndex={i}
                      propertyBeingEditedName="inner_chamfer"
                      dbKey={info.item_key}
                    />
                    <svg
                      fill="#000000"
                      height="200px"
                      width="200px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      onClick={() => {
                        deleteTableRow(i);
                      }}
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M465.423,48.241h-137.61V23.955C327.813,10.746,317.082,0,303.893,0h-95.785c-13.19,0-23.92,10.746-23.92,23.955V48.24 H46.577c-6.655,0-12.049,5.394-12.049,12.049c0,6.655,5.394,12.049,12.049,12.049h22.332l15.228,396.396 C85.069,492.995,104.818,512,129.099,512h253.804c24.281,0,44.03-19.006,44.96-43.267l15.228-396.396h22.332 c6.653,0,12.049-5.394,12.049-12.049C477.472,53.635,472.078,48.241,465.423,48.241z M208.285,24.097h95.43v24.143h-95.43V24.097z M403.784,467.809c-0.433,11.268-9.605,20.094-20.882,20.094H129.099c-11.276,0-20.448-8.827-20.882-20.095L93.025,72.338h325.952 L403.784,467.809z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M182.63,181.571c-0.127-6.575-5.494-11.817-12.042-11.817c-0.078,0-0.158,0-0.236,0.002 c-6.652,0.128-11.943,5.626-11.815,12.278l3.781,196.634c0.126,6.575,5.495,11.817,12.042,11.817c0.078,0,0.158,0,0.236-0.002 c6.653-0.128,11.943-5.624,11.815-12.278L182.63,181.571z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M255.998,169.753c-6.654,0-12.049,5.394-12.049,12.049v196.634c0,6.654,5.394,12.049,12.049,12.049 c6.655,0,12.049-5.394,12.049-12.049V181.802C268.047,175.148,262.653,169.753,255.998,169.753z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M341.645,169.756c-6.628-0.147-12.151,5.162-12.278,11.815l-3.781,196.634c-0.129,6.653,5.162,12.15,11.815,12.278 c0.078,0.001,0.158,0.002,0.236,0.002c6.546,0,11.916-5.244,12.042-11.817l3.781-196.634 C353.588,175.38,348.299,169.883,341.645,169.756z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => {
                setEditMenuState({
                  url: `${serverUrl}product-table/add-row/${info.item_key}`,
                  propertiesArray: [
                    {
                      title: "Артикул",
                      initialValue: "",
                      propertyName: "vendor_code",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "Условный проход, Ду",
                      initialValue: "",
                      propertyName: "nominal_diameter",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "G, дюймы",
                      initialValue: "",
                      propertyName: "groove_diameter",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "L",
                      initialValue: "",
                      propertyName: "l_length",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "L2",
                      initialValue: "",
                      propertyName: "l2_length",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "S",
                      initialValue: "",
                      propertyName: "s_wall_thickness",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "S2",
                      initialValue: "",
                      propertyName: "s2_wall_thickness",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "Диаметр выборки, мм",
                      initialValue: "",
                      propertyName: "sampling_diameter",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "Масса, кг",
                      initialValue: "",
                      propertyName: "weight",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "Резьба",
                      initialValue: "",
                      propertyName: "thread",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "Фаска внутренняя, со стороны резьбы",
                      initialValue: "",
                      propertyName: "inner_chamfer",
                      type: "text",
                      dataType: "string",
                    },
                  ],
                });
                setDisplayEditMenu(true);
              }}
            >
              {" "}
              <svg
                fill="#000000"
                height="38px"
                width="200px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 455 455"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5 "></polygon>{" "}
                </g>
              </svg>
            </button>
          </div>
        </div>
      ) : selectedItemTitle.toLowerCase() == "видео" ? (
        <div className="info_content">
          <div className="info_videos_wrapper">
            <h1>ВИДЕО ИСПЫТАНИЙ МУФТЫ АСТРА</h1>
            <div className="info_heading_underline"></div>
            <div className="info_videos">
              {info?.item_youtube_embeddings?.videos.map((e, i) => (
                <div>
                  <iframe
                    className="video_item"
                    width="1150"
                    height="681"
                    src={e.url}
                    title={e.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    frameBorder={0}
                  ></iframe>
                  <div className="control_buttons">
                    <span
                      className="edit_button"
                      onClick={() => {
                        setEditMenuState({
                          url: `${serverUrl}videos/edit-video/${info.item_key}`,
                          propertiesArray: [
                            {
                              title: "Название видео",
                              initialValue: e.title,
                              propertyName: "title",
                              type: "text",
                              dataType: "string",
                            },
                            {
                              title: "Ссылка на видео",
                              initialValue: e.url,
                              propertyName: "url",
                              type: "text",
                              dataType: "string",
                            },
                            {
                              title: "Ссылка на видео",
                              initialValue: i,
                              propertyName: "video_index",
                              type: "text",
                              dataType: "string",
                              notEditable: true,
                            },
                          ],
                        });
                        setDisplayEditMenu(true);
                      }}
                    >
                      Изменить
                    </span>
                    <span
                      className="edit_button"
                      onClick={() => {
                        deleteVideo(i);
                      }}
                    >
                      Удалить
                    </span>
                  </div>
                </div>
              ))}
              <button
                className="add_video_button"
                onClick={() => {
                  setEditMenuState({
                    url: `${serverUrl}videos/add-video/${info.item_key}`,
                    propertiesArray: [
                      {
                        title: "Название видео",
                        initialValue: "",
                        propertyName: "title",
                        type: "text",
                        dataType: "string",
                      },
                      {
                        title: "Ссылка на видео",
                        initialValue: "",
                        propertyName: "url",
                        type: "text",
                        dataType: "string",
                      },
                    ],
                  });
                  setDisplayEditMenu(true);
                }}
              >
                <svg
                  fill="#000000"
                  height="200px"
                  width="200px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 455 455"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5 "></polygon>{" "}
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : selectedItemTitle.toLowerCase() == "документы" ? (
        <div className="info_content">
          <div className="info_documents">
            {info?.item_documents?.documents?.map((e, i) => {
              return (
                <>
                  {e.url.includes(".rfa") ? (
                    <div className="document_item_wrapper">
                      <a className="document_item" href={e.url}>
                        <img
                          src="https://ajax-group.ru/wp-content/uploads/2019/02/rfa-266x300.png"
                          alt={e.title}
                          className="rsa_img"
                        />
                        <h6>{e.title}</h6>
                      </a>
                    </div>
                  ) : (
                    <div className="document_item_wrapper">
                      <a className="document_item" href={e.url}>
                        <img src={e.url} alt={e.title} />
                        <h6>{e.title}</h6>
                      </a>
                      <div className="control_buttons">
                        <span
                          className="edit_button"
                          onClick={() => {
                            setEditMenuState({
                              url: `${serverUrl}documents/edit-document/${info.item_key}`,
                              propertiesArray: [
                                {
                                  title: "Название документа",
                                  initialValue: e.title,
                                  propertyName: "title",
                                  type: "text",
                                  dataType: "string",
                                },
                                {
                                  title: "Ссылка на документ",
                                  initialValue: e.url,
                                  propertyName: "url",
                                  type: "text",
                                  dataType: "string",
                                },
                                {
                                  title: "Индекс видео",
                                  initialValue: i,
                                  propertyName: "document_index",
                                  type: "text",
                                  dataType: "string",
                                  notEditable: true,
                                },
                              ],
                            });
                            setDisplayEditMenu(true);
                          }}
                        >
                          Изменить
                        </span>
                        <span
                          className="edit_button"
                          onClick={() => {
                            deleteDocument(i);
                          }}
                        >
                          Удалить
                        </span>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            <button
              className="add_document_button"
              onClick={() => {
                setEditMenuState({
                  url: `${serverUrl}documents/add-document/${info.item_key}`,
                  propertiesArray: [
                    {
                      title: "Название документа",
                      initialValue: "",
                      propertyName: "title",
                      type: "text",
                      dataType: "string",
                    },
                    {
                      title: "Ссылка на документ",
                      initialValue: "",
                      propertyName: "url",
                      type: "text",
                      dataType: "string",
                    },
                  ],
                });
                setDisplayEditMenu(true);
              }}
            >
              <svg
                fill="#000000"
                height="200px"
                width="200px"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 455 455"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5 "></polygon>{" "}
                </g>
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div className="info_content">
          <div className="info_reviews_wrapper">
            {info?.item_reviews?.reviews?.length > 0 ? (
              <div>
                <div className="reviews_heading">
                  <h3>
                    {info.item_reviews.reviews?.length}{" "}
                    {parseInt(
                      `${info.item_reviews.reviews?.length}`[
                        info.item_reviews.reviews?.length - 1
                      ]
                    ) == 1
                      ? "ОТЗЫВ НА"
                      : parseInt(
                          `${info.item_reviews.reviews?.length}`[
                            info.item_reviews.reviews?.length - 1
                          ]
                        ) > 1 &&
                        parseInt(
                          `${info.item_reviews.reviews?.length}`[
                            info.item_reviews.reviews?.length - 1
                          ]
                        ) < 5
                      ? "ОТЗЫВА НА"
                      : "ОТЗЫВОВ НА"}
                  </h3>
                  <p>{info.item_title.toUpperCase()}</p>
                </div>
                <div className="info_reviews">
                  {info.item_reviews?.reviews?.map((e, i) => (
                    <div className="review_item">
                      {
                        <div>
                          <span>{`${e.person_name} – ${getDate(
                            e.timestamp
                          )}`}</span>
                          <p>{e.text}</p>
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <h3>ОТЗЫВЫ</h3>
                <p>Отзывов пока нет</p>
              </>
            )}
            <div className="reviews_underline"></div>
            <form
              className="review_form"
              onSubmit={(e) => postReview(e)}
              action=""
            >
              <h4>Добавить отзыв</h4>
              <p>
                Ваш адрес email не будет опубликован. Обязательные поля помечены
                *
              </p>
              <div className="form_field">
                <span>Имя</span>
                <input
                  type="text"
                  onChange={(e) => setUsernameValue(e.target.value)}
                />
              </div>
              <div className="form_field">
                <span>Email</span>
                <input
                  type="text"
                  onChange={(e) => setUserEmailValue(e.target.value)}
                />
              </div>
              <div className="star_rating_field">
                <span>ВАША ОЦЕНКА *</span>
                <div
                  className="star_rating"
                  onMouseLeave={() => setHoveredStarValue(-1)}
                >
                  {Array(5)
                    .fill(null)
                    .map((e, i) => (
                      <div key={i}>
                        {selectedStarValue ? (
                          <>
                            {selectedStarValue < i + 1 ? (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                fill="#000000"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 36.09 36.09"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M36.042,13.909c-0.123-0.377-0.456-0.646-0.85-0.688l-11.549-1.172L18.96,1.43c-0.16-0.36-0.519-0.596-0.915-0.596 s-0.755,0.234-0.915,0.598L12.446,12.05L0.899,13.221c-0.394,0.04-0.728,0.312-0.85,0.688c-0.123,0.377-0.011,0.791,0.285,1.055 l8.652,7.738L6.533,34.045c-0.083,0.387,0.069,0.787,0.39,1.02c0.175,0.127,0.381,0.191,0.588,0.191 c0.173,0,0.347-0.045,0.503-0.137l10.032-5.84l10.03,5.84c0.342,0.197,0.77,0.178,1.091-0.059c0.32-0.229,0.474-0.633,0.391-1.02 l-2.453-11.344l8.653-7.737C36.052,14.699,36.165,14.285,36.042,13.909z M25.336,21.598c-0.268,0.24-0.387,0.605-0.311,0.957 l2.097,9.695l-8.574-4.99c-0.311-0.182-0.695-0.182-1.006,0l-8.576,4.99l2.097-9.695c0.076-0.352-0.043-0.717-0.311-0.957 l-7.396-6.613l9.87-1.002c0.358-0.035,0.668-0.264,0.814-0.592l4.004-9.077l4.003,9.077c0.146,0.328,0.456,0.557,0.814,0.592 l9.87,1.002L25.336,21.598z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 47.94 47.94"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>{" "}
                                </g>
                              </svg>
                            )}
                          </>
                        ) : (
                          <>
                            {hoveredStarValue < i ? (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                fill="#000000"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 36.09 36.09"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M36.042,13.909c-0.123-0.377-0.456-0.646-0.85-0.688l-11.549-1.172L18.96,1.43c-0.16-0.36-0.519-0.596-0.915-0.596 s-0.755,0.234-0.915,0.598L12.446,12.05L0.899,13.221c-0.394,0.04-0.728,0.312-0.85,0.688c-0.123,0.377-0.011,0.791,0.285,1.055 l8.652,7.738L6.533,34.045c-0.083,0.387,0.069,0.787,0.39,1.02c0.175,0.127,0.381,0.191,0.588,0.191 c0.173,0,0.347-0.045,0.503-0.137l10.032-5.84l10.03,5.84c0.342,0.197,0.77,0.178,1.091-0.059c0.32-0.229,0.474-0.633,0.391-1.02 l-2.453-11.344l8.653-7.737C36.052,14.699,36.165,14.285,36.042,13.909z M25.336,21.598c-0.268,0.24-0.387,0.605-0.311,0.957 l2.097,9.695l-8.574-4.99c-0.311-0.182-0.695-0.182-1.006,0l-8.576,4.99l2.097-9.695c0.076-0.352-0.043-0.717-0.311-0.957 l-7.396-6.613l9.87-1.002c0.358-0.035,0.668-0.264,0.814-0.592l4.004-9.077l4.003,9.077c0.146,0.328,0.456,0.557,0.814,0.592 l9.87,1.002L25.336,21.598z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                onMouseOver={() => setHoveredStarValue(i)}
                                onClick={() => setSelectedStarValue(i + 1)}
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 47.94 47.94"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>{" "}
                                </g>
                              </svg>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <div className="form_field">
                <span>Ваш отзыв *</span>
                <textarea
                  ref={reviewFieldRef}
                  className="form_review_input"
                  spellCheck={false}
                  required
                />
              </div>
              <input
                type="submit"
                className="form_submit_button"
                value="ОТПРАВИТЬ"
              ></input>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
